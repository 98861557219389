import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginContext from "../../context/Login/LoginContext";
import { Table, Button } from 'antd';
import { BASE_URL, BASE_URL_SOCKET } from "../../services/api/url/baseUrl";
import { Base64 } from 'js-base64';
import './Issues.css'
import { io } from "socket.io-client"

const Issues = () => {
    const { isLogged, dataLogin } = useContext(LoginContext);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [statusIssues, setStatusIssues] = useState([]);
    const [socket, setSocket] = useState(null);
    const autentication = Base64.encode(dataLogin.email + ":" + dataLogin.jira_api_token)
    const navigate = useNavigate();
    const token = localStorage.getItem('token')

    useEffect(() => {
        const socket = io(BASE_URL_SOCKET, {
            extraHeaders: {
                Authorization: 'Bearer ' + token,
                ContentType: 'application/json',
                Accept: 'application/json',
            },
            cors: {
                origin: "*",
            },
            transports: ['websocket'],
            method: "GET",
            pingInterval: 10000,
            pingTimeout: 5000,
        })
        setSocket(socket)
        return () => {
            socket.disconnect()
        }
    }, [])


    console.log(socket?.connected)
    const handleClick = async () => {
        setLoading(true)
        await fetch(`${BASE_URL}/report/register`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                username: "ClinIA",
                dni: null,
                email: "support@emprinet.com",
                problem_type: "Error",
                endpoint: "https://test-municipalidad.clinia.com.ar:9122/api/v1/user/register/patient",
                data: {
                    dni: "41259863",
                    email: "agustina@gmail.com",
                    phone: 3463455764,
                    pass_email: "Rago41259863!",
                    name: "Agustina",
                    surname: "Rago",
                    gender: "Femenino",
                    born: "1998-11-10",
                    country: "Argentina",
                    province: "Buenos Aires",
                    location: "Ezpeleta",
                    address: "RIO GALLEGOS 444",
                    cuil_cuit: null,
                    id_typeuser: null,
                    email_verify: false,
                    phone_verify: false,
                    signature_url: null
                },
                error: "TypeError: Failed to fetch"
            }),
        }
        )
        socket.emit("report_register", autentication, token)
    }

    useEffect(() => {
        if (socket) {
            socket.on('report', (data) => {
                setLoading(false)
                setList(data.data)
                fetchStatusIssue(token)
            });
        }
    }, [socket]);

    const fetchData = async () => {
        if (autentication) {
            await fetch(`${BASE_URL}/report/list/${autentication}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            })
                .then(res => res.json())
                .then(data => {
                    setLoading(false)
                    setList(data.data)
                    fetchStatusIssue(token)
                })
        }
    }

    const fetchStatusIssue = async (token) => {
        await fetch(`${BASE_URL}/jira/status/${autentication}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'Authorization': 'Bearer ' + token,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                data?.data?.sort((a, b) => {
                    if (parseInt(a.id) < parseInt(b.id)) {
                        return -1;
                    }
                    if (parseInt(a.id) > parseInt(b.id)) {
                        return 1;
                    }
                    return 0;
                });
                setStatusIssues(data?.data?.map((status) => {
                    return {
                        value: parseInt(status.id),
                        text: status.name,
                    }
                }))
            });
    };

    useEffect(() => {
        if (loading) {
            fetchData()
        }
    }, [loading])

    useEffect(() => {
        if (!isLogged) {
            return navigate("/login")
        }
    }, [isLogged, navigate])

    const handleNavigate = (id) => {
        navigate(`/issue_detail/${id}`)
    }

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'DNI',
            dataIndex: 'dni',
            key: 'dni',
        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
        },
        {
            title: 'Tarea en Jira',
            key: 'jira_task',
            dataIndex: 'jira_task',
            render: (text) => (text ? text : "No hay tarea asignada"),
        },
        {
            title: 'Asignado a',
            key: 'assigned_to',
            dataIndex: 'assigned_to',
        },
        {
            title: 'Tipo',
            key: 'problem_type',
            dataIndex: 'problem_type',
            filters: [
                {
                    text: 'Error',
                    value: 'Error',
                },
                {
                    text: 'Mejora',
                    value: 'Mejora',
                },
            ],
            onFilter: (value, record) => record.type.indexOf(value) === 0,
            sorter: (a, b) => {
                if (a.type < b.type) { return -1; }
                if (a.type > b.type) { return 1; }
                return 0;
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Estado',
            key: 'issue_status',
            dataIndex: 'issue_status',
            filters: statusIssues,
            onFilter: (value, record) => record.issue_status_value === value,
            sorter: (a, b) => {
                if (a.issue_status < b.issue_status) { return -1; }
                if (a.issue_status > b.issue_status) { return 1; }
                return 0;
            },
            sortDirections: ['descend', 'ascend'],
            render: (text) => (text)
        },
        {
            title: 'Fecha de creación',
            key: 'create_date',
            dataIndex: 'create_date',
        },
    ];

    return (
        <>
            <Button type="primary" onClick={() => handleClick()}>Cargar reporte</Button>
            <Table
                columns={columns}
                dataSource={list}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => { handleNavigate(record.key) },
                    };
                }}
                rowClassName={record => list.jira_api_token && 'disabled-row'}
                style={{ cursor: !list.jira_api_token ? 'pointer' : 'not-allowed' }}
                loading={loading}
            />
        </>
    )
}

export default Issues;