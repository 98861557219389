import { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Breadcrumb, Layout, Menu, theme, Modal, notification, Result, Button } from 'antd';
import { Routes, Route } from 'react-router-dom';
import Issues from '../../pages/Issues/Issues';
import Login from '../../pages/Login/Login';
import Profile from '../../pages/Profile/Profile';
import Home from '../../pages/Home/Home';
import LoginContext from '../../context/Login/LoginContext';
import IssueDetail from '../../pages/IssueDetail/IssueDetail';
import Switch from '../Swith/Switch';
import logo from '../../assets/images/logo.png';
import robot from '../../assets/images/robot.png';
import { BASE_URL } from '../../services/api/url/baseUrl';
import Politics from '../../pages/Politics/Politics';
import './Layout.css';

import {
    UserOutlined,
    HomeOutlined,
    ImportOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const items = [
    getItem('Inicio', '1', <HomeOutlined />),
    getItem('Perfil', '2', <UserOutlined />),
    getItem('Reportes', '3', <ExclamationCircleOutlined />),
    getItem('Cerrar Sesion', '4', <ImportOutlined />),
];

const LayoutComponent = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { isLogged, toggleLogin, setDarkMode, darkMode } = useContext(LoginContext);
    const [api, contextHolder] = notification.useNotification();
    const [defaultButtonSelected, setDefaultButtonSelected] = useState('1');
    const navigate = useNavigate();
    const location = useLocation();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    useEffect(() => {
        switch (location.pathname) {
            case '/profile':
                setDefaultButtonSelected('2');
                break;
            case '/issues':
                setDefaultButtonSelected('3');
                break;
            default:
                setDefaultButtonSelected('1');
        }
    }, [location])

    const HadleClick = (key) => {
        switch (key) {
            case '1':
                navigate('/');
                break;
            case '2':
                navigate('profile');
                break;
            case '3':
                navigate('issues');
                break;
            case '4':
                setShowModal(true);
                break;
        }
    }

    const OpenSuccessNotification = (placement, message, description) => {
        api.success({
            message: message,
            description: description,
            placement,
            duration: 5
        });
    };

    const OpenErrorNotification = (placement, message, description) => {
        api.error({
            message: message,
            description: description,
            placement,
            duration: 5
        });
    };

    const HandleMode = () => {
        setDarkMode(!darkMode)
        localStorage.setItem("darkMode", !darkMode)
    }

    const HandleLogout = () => {
        const token = localStorage.getItem("token")
        fetch(`${BASE_URL}/user/logout`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200) {
                    toggleLogin({});
                    navigate("/login")
                } else {
                    alert("Error al cerrar sesion")
                }
            }).catch(err => {
                console.log(err)
            })
        setShowModal(false)
    }

    return (
        <>
            {contextHolder}
            <Layout
                style={{
                    minHeight: '100vh',
                    textAlign: 'center',
                }}
            >
                {isLogged ?
                    <Sider
                        collapsible
                        collapsed={collapsed}
                        onCollapse={(value) => setCollapsed(value)}
                        theme={darkMode ? "dark" : "light"}>
                        <div
                            style={{
                                height: 32,
                                margin: 16,
                                background: 'rgba(255, 255, 255, 0.7)',
                            }}
                        >
                            {!collapsed ?
                                <img style={{
                                    height: 32,
                                }} src={logo} alt="logo" /> :
                                <img style={{
                                    height: 32,
                                }} src={robot} alt="robot" />
                            }
                        </div>
                        <Menu
                            theme={darkMode ? "dark" : "light"}
                            defaultSelectedKeys={defaultButtonSelected}
                            mode="inline"
                            items={items}
                            onClick={e => HadleClick(e.key)}
                        />
                    </Sider> : <></>}
                <Layout className="site-layout">
                    <Header
                        style={{
                            padding: 0,
                            background: colorBgContainer,
                        }}
                    >
                        <h1>ClinIA support by Emprinet 4.0</h1>
                    </Header>
                    <Content
                        style={{
                            margin: '0 16px',
                        }}
                    >
                        <Breadcrumb
                            style={{
                                margin: '16px 0',
                            }}
                        >
                            {isLogged ?
                                <>
                                    <Breadcrumb.Item>ClinIA support</Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Switch
                                            onClick={() => HandleMode()}
                                            checked={darkMode}
                                        />
                                    </Breadcrumb.Item>
                                </> :
                                <></>
                            }
                        </Breadcrumb>
                        <Routes>
                            <Route path="/login" element={
                                <Login
                                    OpenErrorNotification={(placement, message, description) =>
                                        OpenErrorNotification(placement, message, description)}
                                    OpenSuccessNotification={(placement, message, description) =>
                                        OpenSuccessNotification(placement, message, description)} />} />
                            <Route path="/" element={<Home />} />
                            <Route path="/profile" element={
                                <Profile
                                    OpenErrorNotification={(placement, message, description) =>
                                        OpenErrorNotification(placement, message, description)}
                                    OpenSuccessNotification={(placement, message, description) =>
                                        OpenSuccessNotification(placement, message, description)} />} />
                            <Route path="/issues" element={<Issues />} />
                            <Route path="/issue_detail/:id_issue" element={<IssueDetail />} />
                            <Route path="/politicas-de-seguridad" element={<Politics />} />
                            <Route path="/*" element={<Result
                                status="404"
                                title="404"
                                subTitle="Lo siento, La página que intenta visitar no existe."
                                extra={<Button type='primary' onClick={() => navigate('/')}> Volver al inicio </Button>}
                            />}
                            />
                        </Routes>
                        {!isLogged ?
                            <div className='imageContainer'>
                                <img src={logo} alt="logo" />
                                <img src={robot} alt="robot" />
                            </div> :
                            <></>}
                    </Content>
                    <Footer
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        ClinIA support ©{(new Date()).getFullYear()} Created by Emprinet 4.0
                    </Footer>
                </Layout>
                <Modal title='Cerrar sesión?' onOk={() => HandleLogout()} onCancel={() => setShowModal(false)} open={showModal} />
            </Layout>
        </>
    )
}

export default LayoutComponent;