import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginContext from "../../context/Login/LoginContext";
import { Input, Form, Button } from "antd";
import "./Profile.css";
import { BASE_URL } from "../../services/api/url/baseUrl";
import LoadingModal from "../../components/LoadingModal/LoadingModal";


const Profile = ({ openErrorNotification, openSuccessNotification }) => {
    const { isLogged, dataLogin } = useContext(LoginContext);
    const navigate = useNavigate();
    const [saveChanges, setSaveChanges] = useState(true)
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if (!isLogged) {
            return navigate("/login")
        }
    }, [isLogged, navigate])

    const changePassword = async (oldPassword, newPassword) => {
        const token = localStorage.getItem('token')
        await fetch(`${BASE_URL}/user/modify/password`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                "id_user": dataLogin.id_users,
                "pass_email": oldPassword,
                "new_pass_email": newPassword
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200) {
                    setShowModal(false)
                    openSuccessNotification('top', "Cambios guardados", data.message)
                } else {
                    setShowModal(false)
                    openErrorNotification('top', "Error al guardar los cambios", data.message)
                }
            })
    }
    const changeDataUser = async (data) => {
        const token = localStorage.getItem('token')
        await fetch(`${BASE_URL}/user/modify`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200) {
                    setShowModal(false)
                    openSuccessNotification('top', "Cambios guardados", data.message)
                } else {
                    setShowModal(false)
                    openErrorNotification('top', "Error al guardar los cambios", data.message)
                }
            })
    }

    const handleChange = (e) => {
        setShowModal(true)
        if (e.password && e.newPassword) {
            changePassword(e.password, e.newPassword)
            delete (e.password)
            delete (e.newPassword)
        }
        const cleanData = Object.keys(e).reduce((acc, key) => {
            if (e[key] !== dataLogin[key]) {
                return { ...acc, [key]: e[key] }
            }
            return acc
        }, {})
        cleanData["id_users"] = dataLogin.id_users
        changeDataUser(cleanData)
        setSaveChanges(true)
    }


    return (
        <>
            <Form
                className="profileStyle"
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
                labelAlign="left"
                initialValues={dataLogin}
                onFinish={e => handleChange(e)}
                onValuesChange={() => setSaveChanges(false)}

            >
                <Form.Item
                    label="Nombre"
                    name="name"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Apellido"
                    name="surname"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Contraseña actual"
                    name="password"
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Nueva contraseña"
                    name="newPassword"
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Dirección"
                    name="address"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Fecha de nacimiento"
                    name="born"
                >
                    <Input readOnly />
                </Form.Item>
                <Form.Item
                    label="CUIL-CUIT"
                    name="cuil_cuit"
                >
                    <Input readOnly />
                </Form.Item>
                <Form.Item
                    label="DNI"
                    name="dni"
                >
                    <Input readOnly />
                </Form.Item>
                <Form.Item
                    label="email"
                    name="email"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Teléfono"
                    name="phone"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Jira API Token"
                    name="jira_api_token"
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={saveChanges}
                    >Guardar cambios</Button>
                </Form.Item>
            </Form>
            <LoadingModal showModal={showModal} setShowModal={setShowModal} title="Guardando cambios" />
        </>
    )
}

export default Profile;