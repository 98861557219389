import { useState, useEffect } from "react";
import LoginContext from "./LoginContext";
import jwtDecode from "jwt-decode";
import { BASE_URL } from "../../services/api/url/baseUrl";
import moment from "moment";
import { Base64 } from 'js-base64';
import { ConfigProvider, theme } from 'antd';


const dateFormat = "DD/MM/YYYY";

const LoginProvider = ({ children }) => {
    const [isLogged, setIsLogged] = useState(false)
    const [dataLogin, setDataLogin] = useState({})
    const [darkMode, setDarkMode] = useState(false)
    const [autentication, setAutentication] = useState("")

    async function fetchData() {
        const token = localStorage.getItem('token')
        const darkMode = JSON.parse(localStorage.getItem('darkMode'))
        setDarkMode(darkMode)
        if (token) {
            setIsLogged(true)
            const userId = jwtDecode(token).identity
            await fetch(`${BASE_URL}/user/${userId}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status === 200) {
                        data.data["born"] = moment(new Date(data.data["born"]))
                            .format(dateFormat)
                            .toString()
                        setDataLogin(data.data)
                        setAutentication(Base64.encode(data.data["email"] + ":" + data.data["password"]))
                    }
                }
                )
        }
    }

    useEffect(() => {
        const root = document.documentElement;
        if (darkMode) {
            root.style.setProperty('--backgroundColor', '#000000');
        } else {
            root.style.setProperty('--backgroundColor', '#ffffff');
        }
        fetchData()
    }, [darkMode])

    const toggleLogin = (data) => {
        if (data?.data) {
            data.data["born"] = moment(new Date(data.data["born"]))
                .format(dateFormat)
                .toString()
            localStorage.setItem('token', data.access_token)
        } else {
            localStorage.removeItem('token')
        }
        setDataLogin(data.data)
        localStorage.setItem('darkMode', darkMode)
        setIsLogged(!isLogged)
    }

    return (
        <LoginContext.Provider
            value={{ isLogged, setIsLogged, dataLogin, setDataLogin, toggleLogin, darkMode, setDarkMode, autentication }}
        >
            <ConfigProvider theme={{
                algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
            }}>
                {children}
            </ConfigProvider>
        </LoginContext.Provider>
    )
}

export default LoginProvider