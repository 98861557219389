import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Input, Select, Button, notification } from "antd";
import { BASE_URL } from "../../services/api/url/baseUrl";
// import HandleResponse from "../../services/customHooks/HandleResponse";
import LoginContext from "../../context/Login/LoginContext";
import JiraModal from "../../components/JiraModal/JiraModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import './IssueDetail.css'

const { TextArea } = Input;

const IssueDetail = () => {
    const { id_issue } = useParams();
    const [issue, setIssue] = useState({});
    const [statusIssues, setStatusIssues] = useState([]);
    const { isLogged, dataLogin, autentication } = useContext(LoginContext);
    const [showModal, setShowModal] = useState(false);
    const [jiraTask, setJiraTask] = useState({});
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [titleTask, setTitleTask] = useState("");
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const isDarkMode = JSON.parse(localStorage.getItem("darkMode"));

    useEffect(() => {
        if (!isLogged) {
            return navigate("/login")
        }
    }, [isLogged, navigate])

    const fetchStatusIssue = async (jira_task, token) => {
        await fetch(`${BASE_URL}/jira/transitions/${jira_task}/${autentication}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'Authorization': 'Bearer ' + token,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setStatusIssues(data.data.transitions.map((transition) => {
                    return {
                        value: parseInt(transition.id),
                        label: transition.to.name,
                    }
                }))
            });
    };

    const getIssue = async () => {
        const token = localStorage.getItem("token");
        if (id_issue) {
            await fetch(`${BASE_URL}/report/find/${id_issue}/${autentication}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token,
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setIssue(data.data);
                    if (data.data.jira_task) {
                        fetchStatusIssue(data.data.jira_task, token);
                    }
                });
        }
    };

    useEffect(() => {
        getIssue();
    }, []);

    const handleSelect = async (data) => {
        const token = localStorage.getItem("token");
        setShowLoadingModal(true)
        setTitleTask("Moviendo tarea")
        const response = await fetch(`${BASE_URL}/jira/modify/${autentication}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                issue_task: issue.jira_task,
                transition_id: data.value
            }),
        })
            .then(handleResponse)
        setShowLoadingModal(false)
        if (response.error) {
            const { errors } = JSON.parse(response.message)
            api.error({
                message: 'Error',
                description: JSON.stringify(errors),
                duration: 3,
            })
        } else {
            api.success({
                message: response.message,
                description: `Movido a: ${data.label}`,
                duration: 3,

            })
        }
    }

    useEffect(() => {
        if (Object.keys(jiraTask).length) {
            fetchCreateTask(jiraTask)
        }
    }, [jiraTask])

    const handleResponse = (response) => {
        return response?.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status !== 200 || response.status !== 204) {
                    return { message: data.data, error: true }
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return data;
        });
    }

    const fetchUpdateIssue = async (id_issue, task) => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${BASE_URL}/report/modify`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                id_report: id_issue,
                jira_task: task.key,
                jira_url: task.self,
            }),
        })
            .then(handleResponse)
        setShowModal(false)
        if (response.error) {
            const { errors } = JSON.parse(response.message)
            api.error({
                message: 'Error',
                description: JSON.stringify(errors),
                duration: 3,
            })
        } else {
            api.success({
                message: response.message,
                description: `Tarea Nro: ${task.key}`,
                duration: 3,

            })
        }
    }

    const handleDelete = async () => {
        const token = localStorage.getItem("token");
        setShowLoadingModal(true)
        setTitleTask("Eliminando tarea")
        const response = await fetch(`${BASE_URL}/jira/delete/${issue.jira_task}/${autentication}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(handleResponse)
        setShowModal(false)
        if (response.error) {
            const { errors } = JSON.parse(response.message)
            api.error({
                message: 'Error',
                description: JSON.stringify(errors),
                duration: 3,
            })
        } else {
            const clearData = {
                key: null,
                self: null,
            }
            api.success({
                message: response.message,
                description: `Tarea ${issue.jira_task} elimiada correctamente`,
                duration: 3,
            })
            fetchUpdateIssue(id_issue, clearData)
            setShowLoadingModal(false)
        }
    }

    const fetchCreateTask = async (jiraTask) => {
        setShowLoadingModal(true)
        const response = await fetch(`${BASE_URL}/jira/create_task/${autentication}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + dataLogin.jira_api_token,
            },
            body: JSON.stringify({
                fields: {
                    project: {
                        key: jiraTask.project
                    },
                    summary: jiraTask.title,
                    description: {
                        type: "doc",
                        version: 1,
                        content: [
                            {
                                type: "paragraph",
                                content: [
                                    {
                                        type: "text",
                                        text: `Endpoint: ${issue.endpoint}
                                Data: ${JSON.stringify(issue.data, null, 4)}
                                Error: ${issue.error}
                                Problem Type: ${issue.problem_type}
                                Username: ${issue.username}
                                Date: ${issue.date}`,
                                    },
                                ],
                            },
                        ],
                    },
                    issuetype: {
                        id: jiraTask.issueType
                    },
                    priority: {
                        id: jiraTask.priority
                    },
                    assignee: {
                        accountId: jiraTask.assignee
                    },
                    customfield_10020: jiraTask.sprint,
                    timetracking: {
                        originalEstimate: jiraTask.originalEstimate,
                        remainingEstimate: "0m"
                    }
                }
            })
        })
            .then(handleResponse)
        setShowLoadingModal(false)
        if (response.error) {
            const { errors } = JSON.parse(response.message)
            api.error({
                message: 'Error',
                description: JSON.stringify(errors),
                duration: 3,
            })
        } else {
            fetchUpdateIssue(id_issue, response.data)
        }
    }

    return (
        <Row gutter={[16, 16]}>
            {contextHolder}
            <Col span={12}>
                <p className={`issueTitle ${isDarkMode ? 'issueDark' : 'issueLight'}`}>Data:</p>
                <TextArea
                    value={JSON.stringify(issue.data, null, 4)}
                    autoSize={{ minRows: 10, maxRows: 10 }}
                    readOnly
                />
            </Col>
            <Col span={12}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <p className={`issueTitle ${isDarkMode ? 'issueDark' : 'issueLight'}`}>Endpoint:</p>
                        <Input
                            value={issue.endpoint}
                            readOnly
                        />
                    </Col>
                    <Col span={24}>
                        <p className={`issueTitle ${isDarkMode ? 'issueDark' : 'issueLight'}`}>Email:</p>
                        <Input
                            value={issue.email}
                            readOnly
                        />
                    </Col>
                    <Col span={24}>
                        <p className={`issueTitle ${isDarkMode ? 'issueDark' : 'issueLight'}`}>Username:</p>
                        <Input
                            value={issue.username}
                            readOnly
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={12}>
                <p className={`issueTitle ${isDarkMode ? 'issueDark' : 'issueLight'}`}>Tipo de problema:</p>
                <Input
                    value={issue.problem_type}
                    readOnly
                />
            </Col>
            <Col span={12}>
                <p className={`issueTitle ${isDarkMode ? 'issueDark' : 'issueLight'}`}>Error:</p>
                <Input
                    value={issue.error}
                    readOnly
                />
            </Col>
            <Col span={12}>
                <p className={`issueTitle ${isDarkMode ? 'issueDark' : 'issueLight'}`}>Estado:</p>
                <Input
                    value={issue.issue_status}
                    readOnly
                />
            </Col>
            <Col span={12}>
                <p className={`issueTitle ${isDarkMode ? 'issueDark' : 'issueLight'}`}>Seleccionar:</p>
                <Select
                    style={{ width: '100%' }}
                    onSelect={(value, data) => handleSelect(data)}
                    options={statusIssues}
                />
            </Col>
            <Col span={12}>
                <p className={`issueTitle ${isDarkMode ? 'issueDark' : 'issueLight'}`}>DNI:</p>
                <Input
                    value={issue.dni}
                    readOnly
                />
            </Col>
            <Col span={12}>
                <p className={`issueTitle ${isDarkMode ? 'issueDark' : 'issueLight'}`}>Fecha de creación:</p>
                <Input
                    value={issue.create_date}
                    readOnly
                />
            </Col>
            <Col span={12}>
                <p className={`issueTitle ${isDarkMode ? 'issueDark' : 'issueLight'}`}>Tarea en Jira:</p>
                <Input
                    value={issue.jira_task}
                    readOnly
                />
            </Col>
            <Col span={12}>
                <p className={`issueTitle ${isDarkMode ? 'issueDark' : 'issueLight'}`}>Jira:</p>
                {issue.jira_url ?
                    <div style={{ display: 'flex' }}>
                        <Input
                            style={{ cursor: 'pointer', width: '50%' }}
                            value={`https://emprinet40.atlassian.net/browse/${issue.jira_task}`}
                            readOnly
                            onClick={() => window.open(`https://emprinet40.atlassian.net/browse/${issue.jira_task}`, '_blank')}
                        />
                        <Button
                            danger type="primary"
                            style={{ width: '50%' }}
                            onClick={() => handleDelete()}
                        >
                            Eliminar
                        </Button>
                    </div>
                    : <Button
                        type="primary"
                        style={{ width: '100%' }}
                        disabled={issue.jira_task}
                        onClick={() => {
                            setShowModal(true)
                            setTitleTask("Creando Tarea en Jira")
                        }}
                    >
                        Crear Tarea en Jira
                    </Button>}
            </Col>
            <JiraModal showModal={showModal} setShowModal={setShowModal} setJiraTask={setJiraTask} />
            <LoadingModal showModal={showLoadingModal} title={titleTask} />
        </Row>
    );
};

export default IssueDetail;