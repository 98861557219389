import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginContext from "../../context/Login/LoginContext";
import { Button, Form, Input } from 'antd';
import "./Login.css";
import { BASE_URL } from "../../services/api/url/baseUrl";
import LoadingModal from "../../components/LoadingModal/LoadingModal";

const Login = ({ OpenErrorNotification, OpenSuccessNotification }) => {
    const { isLogged, toggleLogin, darkMode } = useContext(LoginContext);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false)

    const onFinish = (e) => {
        setShowModal(true)
        fetch(`${BASE_URL}/user/login`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                email_dni: e.username,
                pass_email: e.password
            }),
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200) {
                    toggleLogin(data);
                    OpenSuccessNotification('top', "Inicio de sesion exitoso", data.message)
                    navigate("/")
                } else {
                    OpenErrorNotification("top", "Error al iniciar sesion", data.message)
                }
                setShowModal(false)
            }).catch(err => {
                console.log(err)
            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (isLogged) {
            return navigate(-1)
        }
    }, [isLogged, navigate])
    return (
        <div className={`loginStyle ${darkMode ? "dark" : "light"}`}>
            <Form
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={e => onFinish(e)}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Usuario"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Iniciar sesión
                    </Button>
                </Form.Item>
            </Form>
            <LoadingModal showModal={showModal} setShowModal={setShowModal} title="Iniciando Sesión" />
        </div>
    );

}

export default Login;