import { useEffect, useContext, useState } from "react";
import LoginContext from "../../context/Login/LoginContext";
import { BASE_URL } from "../../services/api/url/baseUrl";
import { Modal, Select, Form, Row, Col, Input, Button } from "antd";

const JiraModal = ({ showModal, setShowModal, setJiraTask }) => {
    const [projectsLoader, setProjectsLoader] = useState(true)
    const [projects, setProjects] = useState([]);
    const [listProjects, setListProjects] = useState([]);
    const [issueTypesLoader, setIssueTypesLoader] = useState(true)
    const [issueTypes, setIssueTypes] = useState([]);
    const [prioritiesLoader, setPrioritiesLoader] = useState(true)
    const [priorities, setPriorities] = useState([]);
    const [boardsLoader, setBoardsLoader] = useState(true)
    const [boards, setBoards] = useState([]);
    const [sprintsLoader, setSprintsLoader] = useState(true)
    const [sprints, setSprints] = useState([]);
    const [usersLoader, setUsersLoader] = useState(true)
    const [users, setUsers] = useState([]);
    const [projectKey, setProjectKey] = useState()
    const [issueType, setIssueType] = useState()
    const [board, setBoard] = useState()
    const [sprint, setSprint] = useState()
    const [assignee, setAssignee] = useState()
    const [priority, setPriority] = useState()
    const [title, setTitle] = useState()
    const [originalEstimate, setOriginalEstimate] = useState("0m");
    const [createTask, setCreateTask] = useState(false);
    const user = useContext(LoginContext);
    const token = localStorage.getItem("token");

    const fetchProject = async () => {
        await fetch(`${BASE_URL}/jira/project/${user.autentication}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                const projects = data.data.map((project) => {
                    return {
                        value: project.key,
                        label: `${project.name} - ${project.key}`
                    }
                })
                setListProjects(data.data);
                setProjects(projects);
                setProjectsLoader(false);
            });
    }

    const fetchIssueTypes = async (key) => {
        await fetch(`${BASE_URL}/jira/project/${user.autentication}/${key}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                const issueTypes = data.data.issueTypes.map((issueType) => {
                    return {
                        value: issueType.id,
                        label: <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                            <img style={{ width: '10%' }} src={issueType.iconUrl} alt={`${issueType.name}`} />
                            <span>{issueType.name}</span>
                        </div>
                    }
                })
                setProjectKey(key)
                setIssueTypes(issueTypes);
                setIssueTypesLoader(false);
            });
    }

    const fetchPriority = async (key) => {
        await fetch(`${BASE_URL}/jira/priority/${user.autentication}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                const priority = data.data.map((priority) => {
                    return {
                        value: priority.id,
                        label: <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                            <img style={{ width: '10%' }} src={priority.iconUrl} alt={`${priority.name}`} />
                            <span>{priority.name}</span>
                        </div>
                    }
                })
                setPriorities(priority);
                setPrioritiesLoader(false);
            });
    }

    const fetchBoards = async () => {
        await fetch(`${BASE_URL}/jira/board/${user.autentication}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                const boards = data.data.values.map((board) => {
                    return {
                        value: board.id,
                        label: <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                            <img style={{ width: '10%' }} src={board.location.avatarURI} alt={`${board.location.name}`} />
                            <span>{board.location.name}</span>
                        </div>
                    }
                })
                setBoards(boards);
                setBoardsLoader(false);
            });
    }

    const fetchSprints = async (key) => {
        await fetch(`${BASE_URL}/jira/board/${user.autentication}/${key}/sprint`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                const sprints = data.data.values.map((sprints) => {
                    return {
                        value: sprints.id,
                        label: sprints.name,
                        disabled: sprints.state === "closed"
                    }
                })
                setSprints(sprints);
                setSprintsLoader(false);
                setBoard(key)
            });
    }

    const fetchUsers = async () => {
        await fetch(`${BASE_URL}/jira/users/${user.autentication}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                const users = data.data.map((users) => {
                    return {
                        value: users.accountId,
                        label: <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                            <img style={{ width: '10%' }} src={users.avatarUrls["16x16"]} alt={`${users.displayName}`} />
                            <span>{users.displayName}</span>
                        </div>
                    }
                })
                const defaultUser = data.data.filter((users) => users.displayName.includes(`${user.dataLogin.name} ${user.dataLogin.surname}`))
                setAssignee(defaultUser[0]?.accountId)
                setUsers(users);
                setUsersLoader(false);
            });
    }

    useEffect(() => {
        if(projectKey && title && issueType && priority && originalEstimate && board && sprint && assignee ){
            setCreateTask(true)
        }
    }, [projectKey, title, issueType, priority, originalEstimate, board, sprint, assignee])

    useEffect(() => {
        if (user.isLogged) {
            fetchProject();
            fetchPriority();
            fetchBoards();
            fetchUsers();
        }
    }, [])

    const handleCreateTask = (data) => {
        setJiraTask(data)
        setIssueTypes([])
        setSprints([])
        setProjectKey()
        setIssueType()
        setBoard()
        setSprint()
        setAssignee()
        setPriority()
        setTitle()
        setOriginalEstimate()
        setCreateTask()
    }

    return (
        <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            footer={null}
            title="Crear Tarea en Jira"
        >
            <Form
                layout="vertical"
                onFinish={e => handleCreateTask(e)}
                initialValues={
                    {
                        project: "Seleccionar Proyecto",
                        title: title,
                        issueType: "Seleccionar Tipo de Tarea",
                        priority: "Seleccionar Prioridad",
                        originalEstimate: originalEstimate,
                        assignee: assignee,
                        board: "Seleccionar Tablero",
                        sprint: "Seleccionar Sprint",
                    }
                }
            >
                <Form.Item
                    required={true}
                    label="Proyecto:"
                    name={"project"}
                    value={projectKey}
                >
                    <Select
                        style={{ width: '100%' }}
                        onSelect={(value, data) => fetchIssueTypes(value)}
                        options={projects}
                        loading={projectsLoader}
                    />
                </Form.Item>
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item
                            required={true}
                            label="Título:"
                            name={"title"}
                            value={title}
                        >
                            <Input onChange={e => setTitle(e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            required={true}
                            label="Tipo de Tarea:"
                            name={"issueType"}
                            value={issueType}
                        >
                            <Select
                                style={{ width: '100%' }}
                                options={issueTypes}
                                onSelect={(value, data) => setIssueType(value)}
                                loading={issueTypesLoader}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            required={true}
                            label="Prioridad:"
                            name={"priority"}
                            value={priority}
                        >
                            <Select
                                style={{ width: '100%' }}
                                options={priorities}
                                onSelect={(value, data) => setPriority(value)}
                                loading={prioritiesLoader}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            required={true}
                            label="Estimación original:"
                            name={"originalEstimate"}
                            value={originalEstimate}
                        >
                            <Input onChange={e => setOriginalEstimate(e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            required={true}
                            label="Asignar:"
                            name={"assignee"}
                            value={assignee}
                        >
                            <Select
                                style={{ width: '100%' }}
                                options={users}
                                onSelect={(value, data) => setAssignee(value)}
                                loading={usersLoader}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            required={true}
                            label="Tablero:"
                            name={"board"}
                            value={board}
                        >
                            <Select
                                style={{ width: '100%' }}
                                options={boards}
                                onSelect={(value, data) => fetchSprints(value)}
                                loading={boardsLoader}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            required={true}
                            label="Sprint:"
                            name={"sprint"}
                            value={sprint}
                        >
                            <Select
                                style={{ width: '100%' }}
                                options={sprints}
                                onSelect={(value, data) => setSprint(value)}
                                loading={sprintsLoader}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Button
                                type="primary"
                                danger style={{ width: '100%' }}
                                onClick={() => setShowModal(false)}
                            >
                                Cancelar
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Button
                                type="primary"
                                style={{ width: '100%' }}
                                htmlType="submit"
                                disabled={!createTask}
                            >
                                Crear Tarea
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default JiraModal