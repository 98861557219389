const HandleResponse = (response) => {
    return response?.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('token')
                return JSON.stringify({ message: "Unauthorized", error: true })
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

export default HandleResponse