import './App.css';
import LayoutComponent from './components/Layout/Layout';
import LoginProvider from './context/Login/LoginProvider';


function App() {
    return (
        <LoginProvider>
            <LayoutComponent />
        </LoginProvider >
    );
}

export default App;
