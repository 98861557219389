import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginContext from "../../context/Login/LoginContext";
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { BASE_URL } from "../../services/api/url/baseUrl";
import { Row, Col, Statistic } from "antd";
import HandleResponse from "../../services/customHooks/HandleResponse";
import 'leaflet/dist/leaflet.css'
import L from "leaflet";

const DARK_MODE_URL = "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
const LIGHT_MODE_URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
const API_KEY = '1c4924b6-efcd-48e0-b370-a3a6ecc3a0b7'

const Home = () => {
    const { isLogged, darkMode } = useContext(LoginContext);
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [usersQuantity, setUsersQuantity] = useState(0)

    const customMarker = new L.icon({
        iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40]
    });

    const ChargeLocations = async () => {
        const token = localStorage.getItem('token')
        const response = await fetch(`${BASE_URL}/user_clinia/list/locations`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(HandleResponse)
        setList(response?.data)
        setUsersQuantity(response?.data?.length)
    }

    useEffect(() => {
        if (!isLogged) {
            return navigate("/login")
        }
    }, [isLogged, navigate])


    useEffect(() => {
        ChargeLocations()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            ChargeLocations()
        }, 300000);

    }, [list])


    return (
        <Row>
            <Col span={20} >
                <div style={{ height: '80vh', width: '100%', margin: 'auto' }}>
                    <MapContainer
                        style={{ height: '100%', width: '100%' }}
                        center={[-33.1236, -64.3492]}
                        zoom={14}
                        gestureHandling={true}
                        gestureHandlingOptions={
                            {
                                text: {
                                    touch: "Usar los dos dedos para moverte por el mapa",
                                    scroll: "Usar el ctrl + scroll para zoom en el mapa",
                                    scrollMac: "Usar el ⌘ + scroll para zoom en el mapa"
                                },
                                duration: 1500
                            }
                        }
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url={darkMode ? DARK_MODE_URL : LIGHT_MODE_URL}
                            apikey={API_KEY}
                        />
                        {
                            list?.map((item, index) => {
                                return (
                                    <Marker
                                        icon={customMarker}
                                        key={item.id_user_client}
                                        position={[item.lat, item.lon]}
                                    >
                                        <Popup>
                                            {item.name}
                                        </Popup>
                                    </Marker>
                                )
                            })
                        }
                    </MapContainer>
                </div>
            </Col>
            <Col span={4}>
                <Statistic title="Usuarios activos" value={usersQuantity} />
            </Col>
        </Row>
    )
}

export default Home;