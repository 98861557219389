import './Switch.css'

const Switch = ({checked, onClick = () => {}}) => {
    return (
        <>
            <input type="checkbox" onChange={() => onClick()} checked={checked} id="darkmode-toggle" className="switch" />
            <label htmlFor="darkmode-toggle" className="switch-label">
                <svg xmlns="http://www.w3.org/2000/svg" className='sun' version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64">
                    <g id="Sum">
                        <path d="M17,31.9727993c0,8.295599,6.7157001,15.0206013,15,15.0206013s15-6.7250023,15-15.0206013   c0-8.2957001-6.7157021-15.0206985-15-15.0206985S17,23.6770992,17,31.9727993z M45,31.9727993   c0,7.1779995-5.8317986,13.0178013-13,13.0178013c-7.1681843,0-13-5.8398018-13-13.0178013   c0-7.1780987,5.8318157-13.0178986,13-13.0178986C39.1682014,18.9549007,45,24.7947006,45,31.9727993z" />
                        <path d="M32.7421989,11.7700996c0.5527,0,1-0.4478998,1-1.0014V1.0014c0-0.5535-0.4473-1.0014-1-1.0014   c-0.5527992,0-0.9999981,0.4479-0.9999981,1.0014v9.7672997C31.7422009,11.3221998,32.1893997,11.7700996,32.7421989,11.7700996z" />
                        <path d="M48.6445007,4.6588001C48.1640015,4.3790998,47.5527,4.5443001,47.2783012,5.0235l-4.8838005,8.4578991   c-0.2773018,0.4792004-0.1133003,1.0914001,0.364315,1.3681002c0.1581841,0.0918999,0.3300858,0.1350002,0.5,0.1350002   c0.3456841,0,0.6815834-0.1789999,0.8661842-0.4996996l4.8838158-8.4580002   C49.2860985,5.5476999,49.1221008,4.9355001,48.6445007,4.6588001z" />
                        <path d="M50.7616997,23.0286999c0.1689987,0,0.3408012-0.0429993,0.4990005-0.1340008l8.4580002-4.8835983   c0.4776001-0.2758007,0.6425972-0.8889008,0.3661995-1.3681011c-0.2763023-0.4771996-0.8837013-0.6434994-1.3661995-0.3666992   l-8.4580002,4.8836002c-0.4775009,0.2757988-0.6425018,0.8889999-0.3661995,1.3680992   C50.0790977,22.8488007,50.4160004,23.0286999,50.7616997,23.0286999z" />
                        <path d="M11.7676001,32.7421989c0-0.5533981-0.4473-1.0012989-0.999999-1.0012989H1.0000008c-0.5527,0-1,0.4479008-1,1.0012989   c0,0.5535011,0.4473,1.0014,1,1.0014h9.7676001C11.3203001,33.7435989,11.7676001,33.2957001,11.7676001,32.7421989z" />
                        <path d="M4.2822008,18.0111008L12.7402,22.8946991c0.1582003,0.0910015,0.3291006,0.1340008,0.4990005,0.1340008   c0.3457003,0,0.6827002-0.1798992,0.8671999-0.5007c0.2764149-0.4790993,0.1114149-1.0923004-0.3662004-1.3680992   l-8.4579992-4.8836002c-0.4813852-0.2768002-1.0918002-0.1125011-1.3662002,0.3666992   C3.6396008,17.1222,3.8047006,17.7353001,4.2822008,18.0111008z" />
                        <path d="M20.7411995,14.9844999c0.1699009,0,0.3418007-0.0431004,0.4990005-0.1339998   c0.4785004-0.2777004,0.6426163-0.8899002,0.3661995-1.3681002l-4.8827991-8.4579   c-0.2772999-0.4801998-0.8895998-0.6444001-1.3661995-0.3666997c-0.4785004,0.2776999-0.6425858,0.8898997-0.3662004,1.3680997   l4.8828001,8.4579C20.0596008,14.8055,20.3955002,14.9844999,20.7411995,14.9844999z" />
                        <path d="M32.7421989,52.2318993c-0.5527992,0-0.9999981,0.4478989-0.9999981,1.0014V62.9986   c0,0.5535011,0.4471989,1.0014,0.9999981,1.0014c0.5527,0,1-0.4478989,1-1.0014v-9.7653008   C33.7421989,52.6797981,33.294899,52.2318993,32.7421989,52.2318993z" />
                        <path d="M22.5272999,49.8936996c-0.4813995-0.2795982-1.0907993-0.114399-1.3661995,0.3647995l-4.8838005,8.457901   C16,59.1955986,16.1639996,59.8077011,16.6415997,60.0844994c0.1582165,0.0918999,0.330101,0.1348991,0.5,0.1348991   c0.3457012,0,0.6816006-0.1788979,0.8662167-0.4996986l4.8837833-8.457901   C23.1688995,50.7826004,23.0049,50.1705017,22.5272999,49.8936996z" />
                        <path d="M13.4824009,42.3922005l-8.4580002,4.8837013c-0.4775,0.2756996-0.6425853,0.8888969-0.3662,1.3680992   c0.1846147,0.3206978,0.5215001,0.5006981,0.8671999,0.5006981c0.1689,0,0.3407998-0.0430984,0.4990001-0.1339989   l8.4580002-4.8837013c0.4775-0.2756996,0.6425991-0.8889008,0.3661995-1.3680992   C14.5732002,42.2807007,13.9639006,42.1144981,13.4824009,42.3922005z" />
                        <path d="M63,31.7409h-9.7656021c-0.5527992,0-1,0.4479008-1,1.0012989c0,0.5535011,0.4472008,1.0014,1,1.0014H63   c0.5527,0,1-0.4478989,1-1.0014C64,32.1888008,63.5527,31.7409,63,31.7409z" />
                        <path d="M58.9756012,47.2759018l-8.4580002-4.8837013c-0.4805031-0.2766991-1.0917854-0.1114998-1.3662033,0.3666992   c-0.2763977,0.4791985-0.1113968,1.0923996,0.3662033,1.3680992l8.4580002,4.8837013   c0.1582146,0.0909004,0.3290977,0.1339989,0.4989967,0.1339989c0.3457031,0,0.6826019-0.1800003,0.867218-0.5006981   C59.6181984,48.1647987,59.4530983,47.5516014,58.9756012,47.2759018z" />
                        <path d="M42.8378983,50.2584991c-0.2753983-0.4791985-0.8866997-0.6443977-1.3661995-0.3647995   c-0.4776001,0.2768021-0.6415977,0.8889008-0.3642998,1.3680992l4.8838005,8.457901   c0.1846161,0.3208008,0.5205002,0.4996986,0.8661995,0.4996986c0.169899,0,0.3418007-0.0429993,0.5-0.1348991   c0.4775009-0.2767982,0.6416016-0.8889008,0.3642998-1.3680992L42.8378983,50.2584991z" />
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" className='moon' version="1.1" viewBox="0 0 512 512" enableBackground="new 0 0 512 512">
                    <g>
                        <g>
                            <path d="m275.4,500.7c-135,0-244.7-109.8-244.7-244.7 1.06581e-14-134.9 109.8-244.7 244.7-244.7 8.2,0 16.4,0.4 24.6,1.2 7.2,0.7 13.5,5.2 16.5,11.7s2.4,14.2-1.6,20.2c-23,33.8-35.2,73.3-35.2,114.2 0,105 78.7,192.2 183.2,202.6 7.2,0.7 13.5,5.2 16.5,11.7 3.1,6.5 2.4,14.2-1.6,20.2-45.8,67.4-121.4,107.6-202.4,107.6zm-12.5-448c-106.5,6.5-191.2,95.2-191.2,203.3 1.42109e-14,112.3 91.4,203.7 203.7,203.7 56.4,0 109.6-23.4 147.8-63.7-46.2-11.7-88.1-36.8-120.8-72.6-41.1-45.2-63.8-103.6-63.8-164.6 0.1-37.1 8.4-73.2 24.3-106.1z" />
                        </g>
                    </g>
                </svg>
            </label>
        </>
    )
}

export default Switch