const Politics = () => {
    return (
        <div>
            <h1>
                Políticas de Privacidad
            </h1>
            <p>
                Este documento es parte integrante de los Términos y Condiciones de uso del sitio web www.emprinet.com y productos brindados por Emprinet 4.0 . Mediante la aceptación de los Términos y Condiciones en el momento de la registración el Usuario acepta las políticas aquí contenidas.

                Mediante la presente Emprinet 4.0 notifica a sus Socios su Política de Privacidad en el tratamiento de los datos personales que han suministrado y/o suministren en concordancia a lo establecido por la Ley 25.326 y sus normas complementarias.

                1- Tipo de información que se requiere:
                Los datos personales son suministrados por los Socios o futuros Socios cuando solicitan libre y voluntariamente su ingreso a uno de los productos brindados por Emprinet 4.0, a través del envío de una solicitud en la cual el Socio revela los siguientes datos -y esta enumeración no es limitativa-, nombre, apellido, domicilio, teléfono, dirección de mail, avatar, edad y cuanto dato sea necesario para el eficaz cumplimiento de la relación entre el Socio y la Empresa.

                2- Finalidad de la información que se obtiene:
                Los datos ingresados voluntariamente por los Socios son confidenciales y los mismos son utilizados para proveerle el servicio para el cual se asoció de la manera más eficaz, personalizada y con la mayor calidad posible.

                3- Seguridad en la información:
                La información proporcionada a la Empresa está resguardada tecnológicamente. Emprinet 4.0 ha instalado todos los medios y medidas técnicas a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los Datos Personales.

                4- Confidencialidad de la información:
                Emprinet 4.0 no revelará ni compartirá los datos suministrados por sus socios sin el consentimiento informado del mismo (excepto cuando lo requiera la ley o a solicitud del gobierno). Sí puede la Empresa utilizar la información para enviar al usuario noticias sobre productos, servicios, novedades, etc. que la empresa estime conveniente, pudiendo siempre el titular de los datos optar por no recibir dichos envíos en el futuro.

                5- Derecho de acceso, rectificación y supresión:
                Los datos que suministren los Socios deben ser veraces y actualizados. El titular de los mismos tiene derechos contemplados en el art. 13, 14, 15 y 16 de la Ley 25.326, * podrán ejercer los derechos de acceso, cancelación, rectificación y oposición, a través del correo electrónico soporte@emprinet.com o por correo postal a la dirección de la Empresa.
                La Inteligencia Artificial (IA) es una constelación de tecnologías que permite que las máquina sientan, comprendan, actúen y aprendan para ampliar las capacidades humanas.
            </p>
        </div>
    )
}

export default Politics