import { Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


const LoadingModal = ({ showModal, title }) => {
    return (
        <Modal
            open={showModal}
            closable={false}
            title={title}
            footer={<></>}
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
                style={{ display: "flex", justifyContent: "center" }}
            />
        </Modal>
    )
}

export default LoadingModal